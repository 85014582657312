import React, { useEffect, useState } from "react";
import Topbar from "../elements/Topbar";
import { Layout } from "../layout/Layout";
import { useNavigate } from "react-router-dom";
import { AddResource } from "./AddResource";
import axios from "axios";
import Swal from "sweetalert2";
import { Mapping } from "./Mapping";
import Pagination from "../elements/Pagination";
import ShowPerPageRecords from "../elements/ShowPerPageRecords";

function Resources() {  
  const [resources, setResources] = useState([]);
  const [editId, setEditId] = useState(0);
  const [popupType, setPopupType] = useState('Add');
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState();
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState(null);

  const handleAdd = () => {    
    setEditId(0);
    setPopupType('Add');
    setShow(true);
  }
  const handleEdit = (id) => {
    setEditId(id);
    setPopupType('Edit');
    setShow(true);
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      showCancelButton: true,
      confirmButtonColor: '#45a7c4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) { 
        axios.delete(`/api/resources/delete?id=${id}`).then((res) =>{
          if(res.data.success){                             
            Swal.fire('Deleted!', '', 'success')
            getResources();
          } 
        }).catch((error) => {
          console.log(error);                     
          Swal.fire(error.message, '', 'info')           
        });
      } else if (result.isDenied) {
        Swal.fire('Cancelled!', '', 'info')
      }
    }) 
  }

  useEffect(() => {
    getResources()
  }, [page, limit, searchText])

  const getResources = () => {
    axios.get(`/api/resources?search=${searchText}&page=${page}&limit=${limit}`)
    .then(function (response) {
      let result = response.data;
      if(result.success){
        setResources(result.data)
        setPage(result.page)
        setTotalPages(result.totalPages)
        setTotalRecords(result.totalRecords)
      }
    })
  }

  const handleStatus = (e, id) => { 
    var status = e.target.checked === true ? 'Active' : 'Inactive';
    axios.put(`api/resources/updateStatus?id=${id}`, {status}).then(res => {
      getResources()                
    })
    .catch(err => {
    })
  }

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearchText(value)
  }

  return (
    <Layout>
      <div className="layout-page">
        <div className="content-wrapper">
          <Topbar title="Resources"></Topbar>
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card">
              <div className="card-header">
                <div className="row d-flex align-items-center">
                  <div className="col-md-3">
                    <h4 className="main-title ">Resources</h4>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-end">
                    <button type="button" className="btn btn-primary" onClick={handleAdd}>
                      <span className="bi bi-plus-lg"></span>&nbsp; Add Resource
                    </button>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="border-1 mb-4">
                  <div className="row paginationRj">
                    <div className="col-md-10 mt-3">
                      <ShowPerPageRecords limit={limit} setLimit={setLimit} />
                    </div>
                    <div className="col-md-2 mt-3">
                      <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" onChange={(e)=>handleSearch(e)}/>
                    </div>
                    <div className="table-responsive">
                      <table className="table" id="customer_table">
                        <thead>
                          <tr className="bg-color">
                            <th>Image</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                            {
                              resources.map((resource) => {
                                return (
                                  <tr>
                                    <td>
                                      <img src={resource.thumbnail ? process.env.REACT_APP_S3_IMAGE_BASE_URL+resource.thumbnail : "./assets/img/no-image.jpg"} style={{width: "50px"}} />
                                    </td>
                                    <td>{resource.title}</td>
                                    <td>{resource.type}</td>
                                    <td>
                                      <div class="form-check form-switch mb-2">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={resource.status === 'Active' ? true : false}
                                          onChange={(e) => handleStatus(e, resource.id)}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <span className="bg-theme" onClick={() => handleEdit(resource.id)}>
                                        <i class="bi bi-pencil me-1"></i>
                                      </span>
                                      <span className="bg-trash" onClick={() => handleDelete(resource.id)}>
                                        <i class="bi bi-trash"></i>
                                      </span>
                                      {/* <span className="bg-theme" onClick={() => handleMapping(resource.id)}>
                                        <i class="bi bi-map"></i>
                                      </span> */}
                                    </td>
                                  </tr>
                                )
                              })
                            }
                        </tbody>
                      </table>
                    </div>
                    <Pagination page={page} setPage={setPage} totalPages={totalPages} totalRecords={totalRecords} limit={limit}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddResource type={popupType} show={show} setShow={setShow} editId={editId} setEditId={setEditId} getResources={getResources}/>
      {/* <Mapping show={showMapping} setShow={setShowMapping} editId={editId} setEditId={setEditId} getResources={getResources}/> */}
    </Layout>
  );
}

export default Resources;
